<div
    class="material-input-phone-number__group"
    [class.material-input-phone-number__group--editable]="editOnClickActive"
    [class.material-input-phone-number__group--has-value]="true"
    [class.material-input-phone-number__group--readonly]="!canEdit"
>
    <div class="material-input-phone-number__label"
        >{{ label }}
        @if (required && !disabled) {
            <span class="material-input-phone-number__label-required">*</span>
        }
    </div>
    <div class="material-input-phone-number__input-wrapper">
        <div class="material-input-phone-number__country-wrapper">
            <div
                #origin
                #trigger="cdkOverlayOrigin"
                class="material-input-phone-number__country"
                (click)="openOverlay()"
                cdkOverlayOrigin
            >
                @if (selectedCountry) {
                    <div
                        class="material-input-phone-number__country-flag fi mr-1"
                        [ngClass]="'fi-' + selectedCountry.iso2 | lowercase"
                    ></div>
                    <div class="material-input-phone-number__country-label">{{ selectedCountry.name }}</div>
                    <div class="material-input-phone-number__country-dial">+{{ selectedCountry.dialCode }}</div>
                }
                @if (!selectedCountry) {
                    Select country
                }
                @if (!disabled) {
                    <eb-icon
                        class="material-input-phone-number__country-arrow"
                        [width]="22"
                        icon="ui/arrow/simple/down"
                    />
                }
            </div>
            <div class="material-input-phone-number__bar"></div>
        </div>

        <div class="material-input-phone-number__phone-wrapper">
            <input
                type="text"
                class="material-input-phone-number__input"
                [disabled]="disabled"
                [formControl]="fcValue"
                [inputMask]="phoneMask"
                [placeholder]="placeholder"
                [readonly]="!canEdit || !selectedCountry ? 'readonly' : ''"
                (focus)="focusInput(true)"
                (focusout)="focusInput(false)"
            />

            <div class="material-input-phone-number__bar"></div>
        </div>
    </div>
</div>

<ng-template
    #overlay="cdkConnectedOverlay"
    [cdkConnectedOverlayDisableClose]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayWidth]="origin.offsetWidth"
    (backdropClick)="isOpen = false"
    cdkConnectedOverlay
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
>
    <eb-dropdown-panel-alt
        [radius]="true"
        [withNotch]="false"
    >
        <eb-dropdown-panel-body class="material-input-select__dropdown-panel-body">
            <div class="material-input-phone-number__countries-list">
                @for (country of countries; track country.iso2) {
                    <div
                        class="material-input-phone-number__countries-list-item"
                        [class.material-input-phone-number__countries-list-item--selected]="selectedCountry?.iso2 === country.iso2"
                        (click)="selectCountry(country)"
                    >
                        <span
                            class="fi mr-1"
                            [ngClass]="'fi-' + country.iso2 | lowercase"
                        ></span>
                        {{ country.name }} <span class="material-input-phone-number__countries-list-item-dial">+{{ country.dialCode }}</span>
                    </div>
                }
            </div>
        </eb-dropdown-panel-body>
    </eb-dropdown-panel-alt>
</ng-template>

<ng-template
    #baseSelectedLabelTemplate
    let-label="label"
    let-value="value"
>
    {{ label }}
</ng-template>
