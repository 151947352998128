import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import {
    Select,
    Store,
} from '@ngxs/store';
import { Observable } from 'rxjs';

import {
    UpdateApplicationConfigurationAction,
} from '~/app/core/state/configuration/configuration.action';
import { ConfigurationState } from '~/app/core/state/configuration/configuration.state';
import { FeatureFlag } from '~/app/shared/enums/feature-flag.enum';
import { BaseConfigurationService } from '~/app/shared/services/base-configuration/base-configuration.service';
import {
    AuthenticationModel,
    LogoModel,
    MatomoTrackingModel,
    SentryTrackingModel,
    ThemeModelThemeColors,
} from '~/app/shared/types/envestboard-api';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationFacade implements BaseConfigurationService {
    @Select(ConfigurationState.themeColors)
    public colorPalette$!: Observable<ThemeModelThemeColors>;

    @Select(ConfigurationState.challengeRequired)
    public challengeRequired$!: Observable<boolean>;

    @Select(ConfigurationState.guestAllowed)
    public guestAllowed$!: Observable<boolean>;

    @Select(ConfigurationState.themeLogos)
    public logo$!: Observable<LogoModel | undefined>;

    @Select(ConfigurationState.phoneVerificationRequired)
    public phoneVerificationRequired$!: Observable<boolean>;

    @Select(ConfigurationState.hasFreeTrial)
    public hasFreeTrial$!: Observable<boolean>;

    @Select(ConfigurationState.authentication)
    public authentication$!: Observable<AuthenticationModel | undefined>;

    constructor(
        private store: Store
    ) {
    }

    public getHasRegistrationSnapshot() {
        return this.store.selectSnapshot(ConfigurationState.hasRegistration);
    }

    public getIsMatomoTrackingEnabledSnapshot() {
        return this.store.selectSnapshot(ConfigurationState.isMatomoTrackingEnabled);
    }

    public getPlatformNameSnapshot() {
        return this.store.selectSnapshot(ConfigurationState.platformName) ?? '';
    }

    public getThemeReportingBackgroundUrlSnapshot() {
        return this.store.selectSnapshot(ConfigurationState.reportingBackgroundUrl) ?? '';
    }

    public getTrackingSentrySnapshot(): SentryTrackingModel | undefined {
        return this.store.selectSnapshot(ConfigurationState.trackingSentry);
    }

    public getTrackinMatomoSnapshot(): MatomoTrackingModel | undefined {
        return this.store.selectSnapshot(ConfigurationState.trackingMatomo);
    }

    public getFeatureFlagByFeatureFlagNameSnapshot(featureFlagName: FeatureFlag): boolean {
        return this.store.selectSnapshot(ConfigurationState.featureFlagsSelector(featureFlagName)) ?? false;
    }

    public getChallengeRequiredSnapshot() : boolean {
        return this.store.selectSnapshot(ConfigurationState.challengeRequired);
    }

    public getChallengeSiteKeySnapshot() : string | null | undefined {
        return this.store.selectSnapshot(ConfigurationState.challengeSiteKey);
    }

    public getAuthenticationSnapshot() : AuthenticationModel | undefined {
        return this.store.selectSnapshot(ConfigurationState.authentication);
    }

    public getCookiesPolicyUrlSnapshot() : string | null | undefined {
        return this.store.selectSnapshot(ConfigurationState.cookiesPolicyUrl);
    }


    public getPrivacyPolicyUrlSnapshot() : string | null | undefined {
        return this.store.selectSnapshot(ConfigurationState.privacyPolicyUrl);
    }


    public getLearnMoreUrlSnapshot() : string | null | undefined {
        return this.store.selectSnapshot(ConfigurationState.learnMoreUrl);
    }

    public getGraphColorsSnapshot(): (string | null)[] {
        return this.store.selectSnapshot(ConfigurationState.graphColors);
    }

    public getThemeLogosSnapshot() : LogoModel | undefined {
        return this.store.selectSnapshot(ConfigurationState.themeLogos);
    }


    public getConfiguration() : Observable<any> {

        return this.store.dispatch(new UpdateApplicationConfigurationAction());
    }
}
