import {
    CountryCallingCode,
    CountryCode,
} from 'libphonenumber-js';

export type Country = {
    name: string,
    iso2: CountryCode,
    dialCode: CountryCallingCode,
    mask: string,
    example: string,
    priority: number,
    areaCodes: string[] | null,
}

export const countries: Country[] = [
    {
        name: 'Afghanistan (‫افغانستان‬‎)',
        iso2: 'AF',
        dialCode: '93',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+93 70 123 4567',
    },
    {
        name: 'Albania (Shqipëri)',
        iso2: 'AL',
        dialCode: '355',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+355 67 212 3456',
    },
    {
        name: 'Algeria (‫الجزائر‬‎)',
        iso2: 'DZ',
        dialCode: '213',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+213 551 23 45 67',
    },
    {
        name: 'American Samoa',
        iso2: 'AS',
        dialCode: '1684',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 684 733 1234',
    },
    {
        name: 'Andorra',
        iso2: 'AD',
        dialCode: '376',
        priority: 0,
        areaCodes: null,
        mask: '999 999',
        example: '+376 312 345',
    },
    {
        name: 'Angola',
        iso2: 'AO',
        dialCode: '244',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+244 923 123 456',
    },
    {
        name: 'Anguilla',
        iso2: 'AI',
        dialCode: '1264',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 264 235 1234',
    },
    {
        name: 'Antigua and Barbuda',
        iso2: 'AG',
        dialCode: '1268',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 268 464 1234',
    },
    {
        name: 'Argentina',
        iso2: 'AR',
        dialCode: '54',
        priority: 0,
        areaCodes: null,
        mask: '9 99 9999 9999',
        example: '+54 9 11 2345 6789',
    },
    {
        name: 'Armenia (Հայաստան)',
        iso2: 'AM',
        dialCode: '374',
        priority: 0,
        areaCodes: null,
        mask: '99 999999',
        example: '+374 77 123456',
    },
    {
        name: 'Aruba',
        iso2: 'AW',
        dialCode: '297',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+297 560 1234',
    },
    {
        name: 'Australia',
        iso2: 'AU',
        dialCode: '61',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+61 412 345 678',
    },
    {
        name: 'Austria (Österreich)',
        iso2: 'AT',
        dialCode: '43',
        priority: 0,
        areaCodes: null,
        mask: '999 999999',
        example: '+43 664 123456',
    },
    {
        name: 'Azerbaijan (Azərbaycan)',
        iso2: 'AZ',
        dialCode: '994',
        priority: 0,
        areaCodes: null,
        mask: '99 999 99 99',
        example: '+994 40 123 45 67',
    },
    {
        name: 'Bahamas',
        iso2: 'BS',
        dialCode: '1242',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 242 359 1234',
    },
    {
        name: 'Bahrain (‫البحرين‬‎)',
        iso2: 'BH',
        dialCode: '973',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+973 3600 1234',
    },
    {
        name: 'Bangladesh (বাংলাদেশ)',
        iso2: 'BD',
        dialCode: '880',
        priority: 0,
        areaCodes: null,
        mask: '9999 999999',
        example: '+880 1812 345678',
    },
    {
        name: 'Barbados',
        iso2: 'BB',
        dialCode: '1246',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 246 250 1234',
    },
    {
        name: 'Belarus (Беларусь)',
        iso2: 'BY',
        dialCode: '375',
        priority: 0,
        areaCodes: null,
        mask: '99 999 99 99',
        example: '+375 29 491 19 11',
    },
    {
        name: 'Belgium (België)',
        iso2: 'BE',
        dialCode: '32',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+32 470 12 34 56',
    },
    {
        name: 'Belize',
        iso2: 'BZ',
        dialCode: '501',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+501 622 1234',
    },
    {
        name: 'Benin (Bénin)',
        iso2: 'BJ',
        dialCode: '229',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+229 90 01 12 34',
    },
    {
        name: 'Bermuda',
        iso2: 'BM',
        dialCode: '1441',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 441 370 1234',
    },
    {
        name: 'Bhutan (འབྲུག)',
        iso2: 'BT',
        dialCode: '975',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+975 17 12 34 56',
    },
    {
        name: 'Bolivia',
        iso2: 'BO',
        dialCode: '591',
        priority: 0,
        areaCodes: null,
        mask: '99999999',
        example: '+591 71234567',
    },
    {
        name: 'Bosnia and Herzegovina (Босна и Херцеговина)',
        iso2: 'BA',
        dialCode: '387',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+387 61 123 456',
    },
    {
        name: 'Botswana',
        iso2: 'BW',
        dialCode: '267',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+267 71 123 456',
    },
    {
        name: 'Brazil (Brasil)',
        iso2: 'BR',
        dialCode: '55',
        priority: 0,
        areaCodes: null,
        mask: '99 99999 9999',
        example: '+55 11 96123 4567',
    },
    {
        name: 'British Indian Ocean Territory',
        iso2: 'IO',
        dialCode: '246',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+246 380 1234',
    },
    {
        name: 'British Virgin Islands',
        iso2: 'VG',
        dialCode: '1284',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 284 300 1234',
    },
    {
        name: 'Brunei',
        iso2: 'BN',
        dialCode: '673',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+673 712 3456',
    },
    {
        name: 'Bulgaria (България)',
        iso2: 'BG',
        dialCode: '359',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+359 43 012 345',
    },
    {
        name: 'Burkina Faso',
        iso2: 'BF',
        dialCode: '226',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+226 70 12 34 56',
    },
    {
        name: 'Burundi (Uburundi)',
        iso2: 'BI',
        dialCode: '257',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+257 79 56 12 34',
    },
    {
        name: 'Cambodia (កម្ពុជា)',
        iso2: 'KH',
        dialCode: '855',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+855 91 234 567',
    },
    {
        name: 'Cameroon (Cameroun)',
        iso2: 'CM',
        dialCode: '237',
        priority: 0,
        areaCodes: null,
        mask: '9 99 99 99 99',
        example: '+237 6 71 23 45 67',
    },
    {
        name: 'Canada',
        iso2: 'CA',
        dialCode: '1',
        priority: 1,
        areaCodes: [
            '204',
            '226',
            '236',
            '249',
            '250',
            '289',
            '306',
            '343',
            '365',
            '387',
            '403',
            '416',
            '418',
            '431',
            '437',
            '438',
            '450',
            '506',
            '514',
            '519',
            '548',
            '579',
            '581',
            '587',
            '604',
            '613',
            '639',
            '647',
            '672',
            '705',
            '709',
            '742',
            '778',
            '780',
            '782',
            '807',
            '819',
            '825',
            '867',
            '873',
            '902',
            '905',
        ],
        mask: '999 999 9999',
        example: '+1 506 234 5678',
    },
    {
        name: 'Cape Verde (Kabu Verdi)',
        iso2: 'CV',
        dialCode: '238',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99',
        example: '+238 991 12 34',
    },
    {
        name: 'Caribbean Netherlands',
        iso2: 'BQ',
        dialCode: '599',
        priority: 1,
        areaCodes: null,
        mask: '999 9999',
        example: '+599 318 1234',
    },
    {
        name: 'Cayman Islands',
        iso2: 'KY',
        dialCode: '1345',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 345 323 1234',
    },
    {
        name: 'Central African Republic (République centrafricaine)',
        iso2: 'CF',
        dialCode: '236',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+236 70 01 23 45',
    },
    {
        name: 'Chad (Tchad)',
        iso2: 'TD',
        dialCode: '235',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+235 63 01 23 45',
    },
    {
        name: 'Chile',
        iso2: 'CL',
        dialCode: '56',
        priority: 0,
        areaCodes: null,
        mask: '9 9999 9999',
        example: '+56 2 2123 4567',
    },
    {
        name: 'China (中国)',
        iso2: 'CN',
        dialCode: '86',
        priority: 0,
        areaCodes: null,
        mask: '999 9999 9999',
        example: '+86 131 2345 6789',
    },
    {
        name: 'Christmas Island',
        iso2: 'CX',
        dialCode: '61',
        priority: 2,
        areaCodes: null,
        mask: '999 999 999',
        example: '+61 412 345 678',
    },
    {
        name: 'Cocos (Keeling) Islands',
        iso2: 'CC',
        dialCode: '61',
        priority: 1,
        areaCodes: null,
        mask: '999 999 999',
        example: '+61 412 345 678',
    },
    {
        name: 'Colombia',
        iso2: 'CO',
        dialCode: '57',
        priority: 0,
        areaCodes: null,
        mask: '999 9999999',
        example: '+57 321 1234567',
    },
    {
        name: 'Comoros (‫جزر القمر‬‎)',
        iso2: 'KM',
        dialCode: '269',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99',
        example: '+269 321 23 45',
    },
    {
        name: 'Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)',
        iso2: 'CD',
        dialCode: '243',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+243 991 234 567',
    },
    {
        name: 'Congo (Republic) (Congo-Brazzaville)',
        iso2: 'CG',
        dialCode: '242',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+242 06 123 4567',
    },
    {
        name: 'Cook Islands',
        iso2: 'CK',
        dialCode: '682',
        priority: 0,
        areaCodes: null,
        mask: '99 999',
        example: '+682 71 234',
    },
    {
        name: 'Costa Rica',
        iso2: 'CR',
        dialCode: '506',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+506 8312 3456',
    },
    {
        name: 'Côte d’Ivoire',
        iso2: 'CI',
        dialCode: '225',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 9999',
        example: '+225 01 23 45 6789',
    },
    {
        name: 'Croatia (Hrvatska)',
        iso2: 'HR',
        dialCode: '385',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+385 92 123 4567',
    },
    {
        name: 'Cuba',
        iso2: 'CU',
        dialCode: '53',
        priority: 0,
        areaCodes: null,
        mask: '9 9999999',
        example: '+53 5 1234567',
    },
    {
        name: 'Curaçao',
        iso2: 'CW',
        dialCode: '599',
        priority: 0,
        areaCodes: null,
        mask: '9 999 9999',
        example: '+599 9 518 1234',
    },
    {
        name: 'Cyprus (Κύπρος)',
        iso2: 'CY',
        dialCode: '357',
        priority: 0,
        areaCodes: null,
        mask: '99 999999',
        example: '+357 96 123456',
    },
    {
        name: 'Czech Republic (Česká republika)',
        iso2: 'CZ',
        dialCode: '420',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+420 601 123 456',
    },
    {
        name: 'Denmark (Danmark)',
        iso2: 'DK',
        dialCode: '45',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+45 32 12 34 56',
    },
    {
        name: 'Djibouti',
        iso2: 'DJ',
        dialCode: '253',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+253 77 83 10 01',
    },
    {
        name: 'Dominica',
        iso2: 'DM',
        dialCode: '1767',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 767 225 1234',
    },
    {
        name: 'Dominican Republic (República Dominicana)',
        iso2: 'DO',
        dialCode: '1',
        priority: 2,
        areaCodes: [
            '809',
            '829',
            '849',
        ],
        mask: '999 999 9999',
        example: '+1 809 234 5678',
    },
    {
        name: 'Ecuador',
        iso2: 'EC',
        dialCode: '593',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+593 99 123 4567',
    },
    {
        name: 'Egypt (‫مصر‬‎)',
        iso2: 'EG',
        dialCode: '20',
        priority: 0,
        areaCodes: null,
        mask: '99 99999999',
        example: '+20 10 01234567',
    },
    {
        name: 'El Salvador',
        iso2: 'SV',
        dialCode: '503',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+503 7012 3456',
    },
    {
        name: 'Equatorial Guinea (Guinea Ecuatorial)',
        iso2: 'GQ',
        dialCode: '240',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+240 222 123 456',
    },
    {
        name: 'Eritrea',
        iso2: 'ER',
        dialCode: '291',
        priority: 0,
        areaCodes: null,
        mask: '9 999 999',
        example: '+291 7 123 456',
    },
    {
        name: 'Estonia (Eesti)',
        iso2: 'EE',
        dialCode: '372',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+372 5123 4567',
    },
    {
        name: 'Ethiopia',
        iso2: 'ET',
        dialCode: '251',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+251 91 123 4567',
    },
    {
        name: 'Falkland Islands (Islas Malvinas)',
        iso2: 'FK',
        dialCode: '500',
        priority: 0,
        areaCodes: null,
        mask: '99999',
        example: '+500 51234',
    },
    {
        name: 'Faroe Islands (Føroyar)',
        iso2: 'FO',
        dialCode: '298',
        priority: 0,
        areaCodes: null,
        mask: '999999',
        example: '+298 211234',
    },
    {
        name: 'Fiji',
        iso2: 'FJ',
        dialCode: '679',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+679 701 2345',
    },
    {
        name: 'Finland (Suomi)',
        iso2: 'FI',
        dialCode: '358',
        priority: 0,
        areaCodes: null,
        mask: '99 9999999',
        example: '+358 41 2345678',
    },
    {
        name: 'France',
        iso2: 'FR',
        dialCode: '33',
        priority: 0,
        areaCodes: null,
        mask: '09 99 99 99 99',
        example: '+33 6 12 34 56 78',
    },
    {
        name: 'French Guiana (Guyane française)',
        iso2: 'GF',
        dialCode: '594',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+594 694 20 12 34',
    },
    {
        name: 'French Polynesia (Polynésie française)',
        iso2: 'PF',
        dialCode: '689',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+689 87 12 34 56',
    },
    {
        name: 'Gabon',
        iso2: 'GA',
        dialCode: '241',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+241 06 03 12 34',
    },
    {
        name: 'Gambia',
        iso2: 'GM',
        dialCode: '220',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+220 301 2345',
    },
    {
        name: 'Georgia (საქართველო)',
        iso2: 'GE',
        dialCode: '995',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+995 555 12 34 56',
    },
    {
        name: 'Germany (Deutschland)',
        iso2: 'DE',
        dialCode: '49',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999999',
        example: '+49 1512 3456789',
    },
    {
        name: 'Ghana (Gaana)',
        iso2: 'GH',
        dialCode: '233',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+233 23 123 4567',
    },
    {
        name: 'Gibraltar',
        iso2: 'GI',
        dialCode: '350',
        priority: 0,
        areaCodes: null,
        mask: '99999999',
        example: '+350 57123456',
    },
    {
        name: 'Greece (Ελλάδα)',
        iso2: 'GR',
        dialCode: '30',
        priority: 0,
        areaCodes: null,
        mask: '999 999 9999',
        example: '+30 691 234 5678',
    },
    {
        name: 'Greenland (Kalaallit Nunaat)',
        iso2: 'GL',
        dialCode: '299',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99',
        example: '+299 22 12 34',
    },
    {
        name: 'Grenada',
        iso2: 'GD',
        dialCode: '1473',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 473 403 1234',
    },
    {
        name: 'Guadeloupe',
        iso2: 'GP',
        dialCode: '590',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+590 690 00 12 34',
    },
    {
        name: 'Guam',
        iso2: 'GU',
        dialCode: '1671',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 671 300 1234',
    },
    {
        name: 'Guatemala',
        iso2: 'GT',
        dialCode: '502',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+502 5123 4567',
    },
    {
        name: 'Guernsey',
        iso2: 'GG',
        dialCode: '44',
        priority: 1,
        areaCodes: null,
        mask: '9999 999999',
        example: '+44 7781 123456',
    },
    {
        name: 'Guinea (Guinée)',
        iso2: 'GN',
        dialCode: '224',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+224 601 12 34 56',
    },
    {
        name: 'Guinea-Bissau (Guiné Bissau)',
        iso2: 'GW',
        dialCode: '245',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+245 955 012 345',
    },
    {
        name: 'Guyana',
        iso2: 'GY',
        dialCode: '592',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+592 609 1234',
    },
    {
        name: 'Haiti',
        iso2: 'HT',
        dialCode: '509',
        priority: 0,
        areaCodes: null,
        mask: '99 99 9999',
        example: '+509 34 10 1234',
    },
    {
        name: 'Honduras',
        iso2: 'HN',
        dialCode: '504',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+504 9123 4567',
    },
    {
        name: 'Hong Kong (香港)',
        iso2: 'HK',
        dialCode: '852',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+852 5123 4567',
    },
    {
        name: 'Hungary (Magyarország)',
        iso2: 'HU',
        dialCode: '36',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+36 20 123 4567',
    },
    {
        name: 'Iceland (Ísland)',
        iso2: 'IS',
        dialCode: '354',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+354 611 1234',
    },
    {
        name: 'India (भारत)',
        iso2: 'IN',
        dialCode: '91',
        priority: 0,
        areaCodes: null,
        mask: '99999 99999',
        example: '+91 81234 56789',
    },
    {
        name: 'Indonesia',
        iso2: 'ID',
        dialCode: '62',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+62 812 345 678',
    },
    {
        name: 'Iran (‫ایران‬‎)',
        iso2: 'IR',
        dialCode: '98',
        priority: 0,
        areaCodes: null,
        mask: '999 999 9999',
        example: '+98 912 345 6789',
    },
    {
        name: 'Iraq (‫العراق‬‎)',
        iso2: 'IQ',
        dialCode: '964',
        priority: 0,
        areaCodes: null,
        mask: '999 999 9999',
        example: '+964 791 234 5678',
    },
    {
        name: 'Ireland',
        iso2: 'IE',
        dialCode: '353',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+353 85 012 3456',
    },
    {
        name: 'Isle of Man',
        iso2: 'IM',
        dialCode: '44',
        priority: 2,
        areaCodes: null,
        mask: '9999 999999',
        example: '+44 7924 123456',
    },
    {
        name: 'Israel (‫ישראל‬‎)',
        iso2: 'IL',
        dialCode: '972',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+972 50 234 5678',
    },
    {
        name: 'Italy (Italia)',
        iso2: 'IT',
        dialCode: '39',
        priority: 0,
        areaCodes: null,
        mask: '999 999 9999',
        example: '+39 312 345 6789',
    },
    {
        name: 'Jamaica',
        iso2: 'JM',
        dialCode: '1876',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 876 210 1234',
    },
    {
        name: 'Japan (日本)',
        iso2: 'JP',
        dialCode: '81',
        priority: 0,
        areaCodes: null,
        mask: '99 9999 9999',
        example: '+81 90 1234 5678',
    },
    {
        name: 'Jersey',
        iso2: 'JE',
        dialCode: '44',
        priority: 3,
        areaCodes: null,
        mask: '9999 999999',
        example: '+44 7797 712345',
    },
    {
        name: 'Jordan (‫الأردن‬‎)',
        iso2: 'JO',
        dialCode: '962',
        priority: 0,
        areaCodes: null,
        mask: '9 9999 9999',
        example: '+962 7 9012 3456',
    },
    {
        name: 'Kazakhstan (Казахстан)',
        iso2: 'KZ',
        dialCode: '7',
        priority: 1,
        areaCodes: [
            '700',
            '701',
            '702',
            '705',
            '707',
            '710',
            '711',
            '712',
            '713',
            '714',
            '715',
            '716',
            '717',
            '718',
            '719',
            '721',
            '722',
            '723',
            '724',
            '725',
            '726',
            '727',
            '728',
            '729',
            '775',
            '777',
            '760',
            '762',
            '763',
            '764',
            '771',
            '751',
            '778',
            '785',
        ],
        mask: '999 999 9999',
        example: '+7 771 000 9998',
    },
    {
        name: 'Kenya',
        iso2: 'KE',
        dialCode: '254',
        priority: 0,
        areaCodes: null,
        mask: '999 999999',
        example: '+254 712 123456',
    },
    {
        name: 'Kiribati',
        iso2: 'KI',
        dialCode: '686',
        priority: 0,
        areaCodes: null,
        mask: '99999999',
        example: '+686 72001234',
    },
    {
        name: 'Kosovo',
        iso2: 'XK',
        dialCode: '383',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+383 43 201 234',
    },
    {
        name: 'Kuwait (‫الكويت‬‎)',
        iso2: 'KW',
        dialCode: '965',
        priority: 0,
        areaCodes: null,
        mask: '999 99999',
        example: '+965 500 12345',
    },
    {
        name: 'Kyrgyzstan (Кыргызстан)',
        iso2: 'KG',
        dialCode: '996',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+996 700 123 456',
    },
    {
        name: 'Laos (ລາວ)',
        iso2: 'LA',
        dialCode: '856',
        priority: 0,
        areaCodes: null,
        mask: '99 99 999 999',
        example: '+856 20 23 123 456',
    },
    {
        name: 'Latvia (Latvija)',
        iso2: 'LV',
        dialCode: '371',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+371 21 234 567',
    },
    {
        name: 'Lebanon (‫لبنان‬‎)',
        iso2: 'LB',
        dialCode: '961',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+961 71 123 456',
    },
    {
        name: 'Lesotho',
        iso2: 'LS',
        dialCode: '266',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+266 5012 3456',
    },
    {
        name: 'Liberia',
        iso2: 'LR',
        dialCode: '231',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+231 77 012 3456',
    },
    {
        name: 'Libya (‫ليبيا‬‎)',
        iso2: 'LY',
        dialCode: '218',
        priority: 0,
        areaCodes: null,
        mask: '99 9999999',
        example: '+218 91 2345678',
    },
    {
        name: 'Liechtenstein',
        iso2: 'LI',
        dialCode: '423',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+423 660 234 567',
    },
    {
        name: 'Lithuania (Lietuva)',
        iso2: 'LT',
        dialCode: '370',
        priority: 0,
        areaCodes: null,
        mask: '999 99999',
        example: '+370 612 34567',
    },
    {
        name: 'Luxembourg',
        iso2: 'LU',
        dialCode: '352',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+352 628 123 456',
    },
    {
        name: 'Macau (澳門)',
        iso2: 'MO',
        dialCode: '853',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+853 6612 3456',
    },
    {
        name: 'Macedonia (FYROM) (Македонија)',
        iso2: 'MK',
        dialCode: '389',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+389 72 345 678',
    },
    {
        name: 'Madagascar (Madagasikara)',
        iso2: 'MG',
        dialCode: '261',
        priority: 0,
        areaCodes: null,
        mask: '99 99 999 99',
        example: '+261 32 12 345 67',
    },
    {
        name: 'Malawi',
        iso2: 'MW',
        dialCode: '265',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+265 991 23 45 67',
    },
    {
        name: 'Malaysia',
        iso2: 'MY',
        dialCode: '60',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+60 12 345 6789',
    },
    {
        name: 'Maldives',
        iso2: 'MV',
        dialCode: '960',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+960 771 2345',
    },
    {
        name: 'Mali',
        iso2: 'ML',
        dialCode: '223',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+223 65 01 23 45',
    },
    {
        name: 'Malta',
        iso2: 'MT',
        dialCode: '356',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+356 9696 1234',
    },
    {
        name: 'Marshall Islands',
        iso2: 'MH',
        dialCode: '692',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+692 235 1234',
    },
    {
        name: 'Martinique',
        iso2: 'MQ',
        dialCode: '596',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+596 696 20 12 34',
    },
    {
        name: 'Mauritania (‫موريتانيا‬‎)',
        iso2: 'MR',
        dialCode: '222',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+222 22 12 34 56',
    },
    {
        name: 'Mauritius (Moris)',
        iso2: 'MU',
        dialCode: '230',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+230 5251 2345',
    },
    {
        name: 'Mayotte',
        iso2: 'YT',
        dialCode: '262',
        priority: 1,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+262 639 01 23 45',
    },
    {
        name: 'Mexico (México)',
        iso2: 'MX',
        dialCode: '52',
        priority: 0,
        areaCodes: null,
        mask: '999 999 9999',
        example: '+52 222 123 4567',
    },
    {
        name: 'Micronesia',
        iso2: 'FM',
        dialCode: '691',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+691 350 1234',
    },
    {
        name: 'Moldova (Republica Moldova)',
        iso2: 'MD',
        dialCode: '373',
        priority: 0,
        areaCodes: null,
        mask: '999 99 999',
        example: '+373 621 12 345',
    },
    {
        name: 'Monaco',
        iso2: 'MC',
        dialCode: '377',
        priority: 0,
        areaCodes: null,
        mask: '9 99 99 99 99',
        example: '+377 6 12 34 56 78',
    },
    {
        name: 'Mongolia (Монгол)',
        iso2: 'MN',
        dialCode: '976',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+976 8812 3456',
    },
    {
        name: 'Montenegro (Crna Gora)',
        iso2: 'ME',
        dialCode: '382',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+382 67 622 901',
    },
    {
        name: 'Montserrat',
        iso2: 'MS',
        dialCode: '1664',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 664 492 3456',
    },
    {
        name: 'Morocco (‫المغرب‬‎)',
        iso2: 'MA',
        dialCode: '212',
        priority: 0,
        areaCodes: null,
        mask: '999 999999',
        example: '+212 650 123456',
    },
    {
        name: 'Mozambique (Moçambique)',
        iso2: 'MZ',
        dialCode: '258',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+258 82 123 4567',
    },
    {
        name: 'Myanmar (Burma)',
        iso2: 'MM',
        dialCode: '95',
        priority: 0,
        areaCodes: null,
        mask: '9 999 9999',
        example: '+95 9 212 3456',
    },
    {
        name: 'Namibia (Namibië)',
        iso2: 'NA',
        dialCode: '264',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+264 81 123 4567',
    },
    {
        name: 'Nauru',
        iso2: 'NR',
        dialCode: '674',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+674 555 1234',
    },
    {
        name: 'Nepal (नेपाल)',
        iso2: 'NP',
        dialCode: '977',
        priority: 0,
        areaCodes: null,
        mask: '999 9999999',
        example: '+977 984 1234567',
    },
    {
        name: 'Netherlands (Nederland)',
        iso2: 'NL',
        dialCode: '31',
        priority: 0,
        areaCodes: null,
        mask: '9 99999999',
        example: '+31 6 12345678',
    },
    {
        name: 'New Caledonia (Nouvelle-Calédonie)',
        iso2: 'NC',
        dialCode: '687',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99',
        example: '+687 75 12 34',
    },
    {
        name: 'New Zealand',
        iso2: 'NZ',
        dialCode: '64',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+64 21 123 4567',
    },
    {
        name: 'Nicaragua',
        iso2: 'NI',
        dialCode: '505',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+505 8123 4567',
    },
    {
        name: 'Niger (Nijar)',
        iso2: 'NE',
        dialCode: '227',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+227 93 12 34 56',
    },
    {
        name: 'Nigeria',
        iso2: 'NG',
        dialCode: '234',
        priority: 0,
        areaCodes: null,
        mask: '999 999 9999',
        example: '+234 802 123 4567',
    },
    {
        name: 'Niue',
        iso2: 'NU',
        dialCode: '683',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+683 888 4012',
    },
    {
        name: 'Norfolk Island',
        iso2: 'NF',
        dialCode: '672',
        priority: 0,
        areaCodes: null,
        mask: '9 99999',
        example: '+672 3 81234',
    },
    {
        name: 'North Korea (조선 민주주의 인민 공화국)',
        iso2: 'KP',
        dialCode: '850',
        priority: 0,
        areaCodes: null,
        mask: '999 999 9999',
        example: '+850 192 123 4567',
    },
    {
        name: 'Northern Mariana Islands',
        iso2: 'MP',
        dialCode: '1670',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 670 234 5678',
    },
    {
        name: 'Norway (Norge)',
        iso2: 'NO',
        dialCode: '47',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+47 40 61 23 45',
    },
    {
        name: 'Oman (‫عُمان‬‎)',
        iso2: 'OM',
        dialCode: '968',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+968 9212 3456',
    },
    {
        name: 'Pakistan (‫پاکستان‬‎)',
        iso2: 'PK',
        dialCode: '92',
        priority: 0,
        areaCodes: null,
        mask: '999 9999999',
        example: '+92 301 2345678',
    },
    {
        name: 'Palau',
        iso2: 'PW',
        dialCode: '680',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+680 620 1234',
    },
    {
        name: 'Palestine (‫فلسطين‬‎)',
        iso2: 'PS',
        dialCode: '970',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+970 599 123 456',
    },
    {
        name: 'Panama (Panamá)',
        iso2: 'PA',
        dialCode: '507',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+507 6123 4567',
    },
    {
        name: 'Papua New Guinea',
        iso2: 'PG',
        dialCode: '675',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+675 7012 3456',
    },
    {
        name: 'Paraguay',
        iso2: 'PY',
        dialCode: '595',
        priority: 0,
        areaCodes: null,
        mask: '999 999999',
        example: '+595 961 456789',
    },
    {
        name: 'Peru (Perú)',
        iso2: 'PE',
        dialCode: '51',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+51 912 345 678',
    },
    {
        name: 'Philippines',
        iso2: 'PH',
        dialCode: '63',
        priority: 0,
        areaCodes: null,
        mask: '999 999 9999',
        example: '+63 905 123 4567',
    },
    {
        name: 'Poland (Polska)',
        iso2: 'PL',
        dialCode: '48',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+48 512 345 678',
    },
    {
        name: 'Portugal',
        iso2: 'PT',
        dialCode: '351',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+351 912 345 678',
    },
    {
        name: 'Puerto Rico',
        iso2: 'PR',
        dialCode: '1',
        priority: 3,
        areaCodes: [
            '787',
            '939',
        ],
        mask: '999 999 9999',
        example: '+1 787 234 5678',
    },
    {
        name: 'Qatar (‫قطر‬‎)',
        iso2: 'QA',
        dialCode: '974',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+974 3312 3456',
    },
    {
        name: 'Réunion (La Réunion)',
        iso2: 'RE',
        dialCode: '262',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+262 692 12 34 56',
    },
    {
        name: 'Romania (România)',
        iso2: 'RO',
        dialCode: '40',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+40 712 034 567',
    },
    {
        name: 'Russia (Россия)',
        iso2: 'RU',
        dialCode: '7',
        priority: 0,
        areaCodes: null,
        mask: '999 999 99 99',
        example: '+7 912 345 67 89',
    },
    {
        name: 'Rwanda',
        iso2: 'RW',
        dialCode: '250',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+250 720 123 456',
    },
    {
        name: 'Saint Barthélemy (Saint-Barthélemy)',
        iso2: 'BL',
        dialCode: '590',
        priority: 1,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+590 690 00 12 34',
    },
    {
        name: 'Saint Helena',
        iso2: 'SH',
        dialCode: '290',
        priority: 0,
        areaCodes: null,
        mask: '99999',
        example: '+290 51234',
    },
    {
        name: 'Saint Kitts and Nevis',
        iso2: 'KN',
        dialCode: '1869',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 869 765 2917',
    },
    {
        name: 'Saint Lucia',
        iso2: 'LC',
        dialCode: '1758',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 758 284 5678',
    },
    {
        name: 'Saint Martin (Saint-Martin (partie française))',
        iso2: 'MF',
        dialCode: '590',
        priority: 2,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+590 690 00 12 34',
    },
    {
        name: 'Saint Pierre and Miquelon (Saint-Pierre-et-Miquelon)',
        iso2: 'PM',
        dialCode: '508',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99',
        example: '+508 55 12 34',
    },
    {
        name: 'Saint Vincent and the Grenadines',
        iso2: 'VC',
        dialCode: '1784',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 784 430 1234',
    },
    {
        name: 'Samoa',
        iso2: 'WS',
        dialCode: '685',
        priority: 0,
        areaCodes: null,
        mask: '99 99999',
        example: '+685 72 12345',
    },
    {
        name: 'San Marino',
        iso2: 'SM',
        dialCode: '378',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+378 66 66 12 12',
    },
    {
        name: 'São Tomé and Príncipe (São Tomé e Príncipe)',
        iso2: 'ST',
        dialCode: '239',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+239 981 2345',
    },
    {
        name: 'Saudi Arabia (‫المملكة العربية السعودية‬‎)',
        iso2: 'SA',
        dialCode: '966',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+966 51 234 5678',
    },
    {
        name: 'Senegal (Sénégal)',
        iso2: 'SN',
        dialCode: '221',
        priority: 0,
        areaCodes: null,
        mask: '99 999 99 99',
        example: '+221 70 123 45 67',
    },
    {
        name: 'Serbia (Србија)',
        iso2: 'RS',
        dialCode: '381',
        priority: 0,
        areaCodes: null,
        mask: '99 9999999',
        example: '+381 60 1234567',
    },
    {
        name: 'Seychelles',
        iso2: 'SC',
        dialCode: '248',
        priority: 0,
        areaCodes: null,
        mask: '9 999 999',
        example: '+248 2 510 123',
    },
    {
        name: 'Sierra Leone',
        iso2: 'SL',
        dialCode: '232',
        priority: 0,
        areaCodes: null,
        mask: '99 999999',
        example: '+232 25 123456',
    },
    {
        name: 'Singapore',
        iso2: 'SG',
        dialCode: '65',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+65 8123 4567',
    },
    {
        name: 'Sint Maarten',
        iso2: 'SX',
        dialCode: '1721',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 721 520 5678',
    },
    {
        name: 'Slovakia (Slovensko)',
        iso2: 'SK',
        dialCode: '421',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+421 912 123 456',
    },
    {
        name: 'Slovenia (Slovenija)',
        iso2: 'SI',
        dialCode: '386',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+386 31 234 567',
    },
    {
        name: 'Solomon Islands',
        iso2: 'SB',
        dialCode: '677',
        priority: 0,
        areaCodes: null,
        mask: '99 99999',
        example: '+677 74 21234',
    },
    {
        name: 'Somalia (Soomaaliya)',
        iso2: 'SO',
        dialCode: '252',
        priority: 0,
        areaCodes: null,
        mask: '9 9999999',
        example: '+252 7 1123456',
    },
    {
        name: 'South Africa',
        iso2: 'ZA',
        dialCode: '27',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+27 71 123 4567',
    },
    {
        name: 'South Korea (대한민국)',
        iso2: 'KR',
        dialCode: '82',
        priority: 0,
        areaCodes: null,
        mask: '99 9999 9999',
        example: '+82 10 2000 0000',
    },
    {
        name: 'South Sudan (‫جنوب السودان‬‎)',
        iso2: 'SS',
        dialCode: '211',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+211 977 123 456',
    },
    {
        name: 'Spain (España)',
        iso2: 'ES',
        dialCode: '34',
        priority: 0,
        areaCodes: null,
        mask: '999 99 99 99',
        example: '+34 612 34 56 78',
    },
    {
        name: 'Sri Lanka (ශ්‍රී ලංකාව)',
        iso2: 'LK',
        dialCode: '94',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+94 71 234 5678',
    },
    {
        name: 'Sudan (‫السودان‬‎)',
        iso2: 'SD',
        dialCode: '249',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+249 91 123 1234',
    },
    {
        name: 'Suriname',
        iso2: 'SR',
        dialCode: '597',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+597 741 2345',
    },
    {
        name: 'Svalbard and Jan Mayen',
        iso2: 'SJ',
        dialCode: '47',
        priority: 1,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+47 41 23 45 67',
    },
    {
        name: 'Swaziland',
        iso2: 'SZ',
        dialCode: '268',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+268 7612 3456',
    },
    {
        name: 'Sweden (Sverige)',
        iso2: 'SE',
        dialCode: '46',
        priority: 0,
        areaCodes: null,
        mask: '99 999 99 99',
        example: '+46 70 123 45 67',
    },
    {
        name: 'Switzerland (Schweiz)',
        iso2: 'CH',
        dialCode: '41',
        priority: 0,
        areaCodes: null,
        mask: '99 999 99 99',
        example: '+41 78 123 45 67',
    },
    {
        name: 'Syria (‫سوريا‬‎)',
        iso2: 'SY',
        dialCode: '963',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+963 944 567 890',
    },
    {
        name: 'Taiwan (台灣)',
        iso2: 'TW',
        dialCode: '886',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+886 912 345 678',
    },
    {
        name: 'Tajikistan',
        iso2: 'TJ',
        dialCode: '992',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+992 91 712 3456',
    },
    {
        name: 'Tanzania',
        iso2: 'TZ',
        dialCode: '255',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+255 621 234 567',
    },
    {
        name: 'Thailand (ไทย)',
        iso2: 'TH',
        dialCode: '66',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+66 81 234 5678',
    },
    {
        name: 'Timor-Leste',
        iso2: 'TL',
        dialCode: '670',
        priority: 0,
        areaCodes: null,
        mask: '9999 9999',
        example: '+670 7721 2345',
    },
    {
        name: 'Togo',
        iso2: 'TG',
        dialCode: '228',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99 99',
        example: '+228 90 11 23 45',
    },
    {
        name: 'Tokelau',
        iso2: 'TK',
        dialCode: '690',
        priority: 0,
        areaCodes: null,
        mask: '9999',
        example: '+690 7290',
    },
    {
        name: 'Tonga',
        iso2: 'TO',
        dialCode: '676',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+676 771 5123',
    },
    {
        name: 'Trinidad and Tobago',
        iso2: 'TT',
        dialCode: '1868',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 868 291 1234',
    },
    {
        name: 'Tunisia (‫تونس‬‎)',
        iso2: 'TN',
        dialCode: '216',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+216 20 123 456',
    },
    {
        name: 'Turkey (Türkiye)',
        iso2: 'TR',
        dialCode: '90',
        priority: 0,
        areaCodes: null,
        mask: '999 999 99 99',
        example: '+90 501 234 56 78',
    },
    {
        name: 'Turkmenistan',
        iso2: 'TM',
        dialCode: '993',
        priority: 0,
        areaCodes: null,
        mask: '99 999999',
        example: '+993 66 123456',
    },
    {
        name: 'Turks and Caicos Islands',
        iso2: 'TC',
        dialCode: '1649',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 649 231 1234',
    },
    {
        name: 'Tuvalu',
        iso2: 'TV',
        dialCode: '688',
        priority: 0,
        areaCodes: null,
        mask: '99 9999',
        example: '+688 90 1234',
    },
    {
        name: 'U.S. Virgin Islands',
        iso2: 'VI',
        dialCode: '1340',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+1 340 642 1234',
    },
    {
        name: 'Uganda',
        iso2: 'UG',
        dialCode: '256',
        priority: 0,
        areaCodes: null,
        mask: '999 999999',
        example: '+256 712 345678',
    },
    {
        name: 'Ukraine (Україна)',
        iso2: 'UA',
        dialCode: '380',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+380 50 123 4567',
    },
    {
        name: 'United Arab Emirates (‫الإمارات العربية المتحدة‬‎)',
        iso2: 'AE',
        dialCode: '971',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+971 50 123 4567',
    },
    {
        name: 'United Kingdom',
        iso2: 'GB',
        dialCode: '44',
        priority: 0,
        areaCodes: null,
        mask: '9999 999999',
        example: '+44 7400 123456',
    },
    {
        name: 'United States',
        iso2: 'US',
        dialCode: '1',
        priority: 0,
        areaCodes: null,
        mask: '(999) 999 9999',
        example: '+1 201 555 0123',
    },
    {
        name: 'Uruguay',
        iso2: 'UY',
        dialCode: '598',
        priority: 0,
        areaCodes: null,
        mask: '99 999 999',
        example: '+598 94 231 234',
    },
    {
        name: 'Uzbekistan (Oʻzbekiston)',
        iso2: 'UZ',
        dialCode: '998',
        priority: 0,
        areaCodes: null,
        mask: '99 999 99 99',
        example: '+998 91 234 56 78',
    },
    {
        name: 'Vanuatu',
        iso2: 'VU',
        dialCode: '678',
        priority: 0,
        areaCodes: null,
        mask: '999 9999',
        example: '+678 591 2345',
    },
    {
        name: 'Vatican City (Città del Vaticano)',
        iso2: 'VA',
        dialCode: '39',
        priority: 1,
        areaCodes: null,
        mask: '999 999 9999',
        example: '+39 312 345 6789',
    },
    {
        name: 'Venezuela',
        iso2: 'VE',
        dialCode: '58',
        priority: 0,
        areaCodes: null,
        mask: '999 9999999',
        example: '+58 412 1234567',
    },
    {
        name: 'Vietnam (Việt Nam)',
        iso2: 'VN',
        dialCode: '84',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+84 912 345 678',
    },
    {
        name: 'Wallis and Futuna',
        iso2: 'WF',
        dialCode: '681',
        priority: 0,
        areaCodes: null,
        mask: '99 99 99',
        example: '+681 82 12 34',
    },
    {
        name: 'Western Sahara (‫الصحراء الغربية‬‎)',
        iso2: 'EH',
        dialCode: '212',
        priority: 1,
        areaCodes: null,
        mask: '999 999999',
        example: '+212 650 123456',
    },
    {
        name: 'Yemen (‫اليمن‬‎)',
        iso2: 'YE',
        dialCode: '967',
        priority: 0,
        areaCodes: null,
        mask: '999 999 999',
        example: '+967 712 345 678',
    },
    {
        name: 'Zambia',
        iso2: 'ZM',
        dialCode: '260',
        priority: 0,
        areaCodes: null,
        mask: '99 9999999',
        example: '+260 95 5123456',
    },
    {
        name: 'Zimbabwe',
        iso2: 'ZW',
        dialCode: '263',
        priority: 0,
        areaCodes: null,
        mask: '99 999 9999',
        example: '+263 71 234 5678',
    },
    {
        name: 'Åland Islands',
        iso2: 'AX',
        dialCode: '358',
        priority: 1,
        areaCodes: null,
        mask: '99 9999999',
        example: '+358 41 2345678',
    },
];
