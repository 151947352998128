import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    map,
    Observable,
} from 'rxjs';

import { ApplicationConfiguration } from '~/app/shared/types/configuration/application-configuration.type';
import { TenantConfigurationModel } from '~/app/shared/types/envestboard-api';
import { environment } from '~/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService {
    constructor(
        private http: HttpClient,
    ) {
    }

    getConfiguration(): Observable<ApplicationConfiguration> {
        const localConfiguration = (environment?.applicationConfiguration ?? {}) as unknown as ApplicationConfiguration;

        return this.http.get<TenantConfigurationModel>(
            '/tenants/configurations/current',
        ).pipe(
            map((tenantConfiguration) => ({
                ...localConfiguration,
                ...tenantConfiguration,
            } as ApplicationConfiguration)),
        );
    }
}
