export enum FeatureFlag {
    TECH_FRONT_FUND_PROMOTED_LOGO_ENABLE = 'tech-front-fund-promoted-logo-enable',
    TECH_FRONT_FUND_SCATTER_GRAPH_ENABLE = 'tech-front-fund-scatter-graph-enable',
    TECH_FRONT_FUND_EXCEL_EXPORT_ENABLE = 'tech-front-fund-excel-export-enable',
    TECH_FRONT_FUND_MARKET_CYCLE_ANALYSIS_ENABLE = 'tech-front-fund-marcket-cycle-analysis-enable',
    TECH_FRONT_PORTFOLIO_ENABLE = 'tech-front-portfolio-enable',
    TECH_FRONT_COMMON_SIMULATION_ENABLE = 'tech-front-common-simulation-enable',
    TECH_FRONT_COMMON_PRINTING_ENABLE = 'tech-front-common-printing-enable',
    TECH_FRONT_PROJECTION_ALLOCATION_ENHANCEMENT_ENABLE = 'tech-front-projection-allocation-enhancement-enable',
    TECH_FRONT_AUTHORISATION_ENABLE = 'tech-front-authorisation-enable',
    TECH_FRONT_MARKET_CYCLE_PERFORMANCE_ENABLE = 'tech-front-allocation-enhancement-enable',
    TECH_FRONT_TOP_FUND_ENHANCEMENT_ENABLE = 'tech-front-top-fund-enhancement-enable',
    TECH_FRONT_LEARN_MORE_ENABLE = 'tech-front-learn-more-enable',
    TECH_FRONT_IMPORT_EXTERNAL_PORTFOLIO_ENABLE = 'tech-front-import-external-portfolio-enable',
    TECH_FRONT_SIMULATION_DISCLAIMER_ENABLE = 'tech-front-simulation-disclaimer-enable',
    SIMULATION_SCENARIO = 'SIMULATION_SCENARIO',
    TECH_FRONT_ALLOCATION_ENHANCEMENT_ENABLE = 'tech-front-allocation-enhancement-enable',
}
