import { Injectable } from '@angular/core';
import {
    Action,
    createSelector,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';
import {
    map,
} from 'rxjs';

import { ConfigurationService } from '~/app/core/services/api/configuration/configuration.service';
import {
    UpdateApplicationConfigurationAction,
    UpdateApplicationConfigurationSimpleAction,
} from '~/app/core/state/configuration/configuration.action';
import { FeatureFlag } from '~/app/shared/enums/feature-flag.enum';
import { ApplicationConfiguration } from '~/app/shared/types/configuration/application-configuration.type';
import {
    AuthenticationModel,
    FeatureFlagModel,
    LogoModel,
    ThemeModelThemeColors,
} from '~/app/shared/types/envestboard-api';


export interface ConfigurationStateModel extends ApplicationConfiguration {}

const defaults: ApplicationConfiguration = {
    isImportPortfolioEnabled: false,
};
@State<ConfigurationStateModel>({
    name: 'applicationConfiguration',
    defaults,
})

@Injectable()
export class ConfigurationState {
    constructor(private configurationService: ConfigurationService) {}

    static featureFlagsSelector(featureFlagName: FeatureFlag) {
        return createSelector([ConfigurationState],
            (state: ConfigurationStateModel) => state?.featureFlags?.find((entry: FeatureFlagModel) => entry.key === featureFlagName)?.isEnable);
    }

    @Selector()
    public static isImportPortfolioEnabled(state: ConfigurationStateModel) {
        return state.isImportPortfolioEnabled;
    }

    @Selector()
    public static authentication(state: ConfigurationStateModel): AuthenticationModel | undefined {
        return state.authentication;
    }


    @Selector()
    public static challengeRequired(state: ConfigurationStateModel) : boolean {
        return state.challenge?.required ?? false;
    }

    @Selector()
    public static challengeSiteKey(state: ConfigurationStateModel) : string | null | undefined {
        return state.challenge?.siteKey;
    }

    @Selector()
    public static cookiesPolicyUrl(state: ConfigurationStateModel) : string | null | undefined {
        return state.cookiesPolicyUrl;
    }


    @Selector()
    public static graphColors(state: ConfigurationStateModel) : (string | null)[] {
        return state.theme?.graphColors ?? [];
    }

    @Selector()
    public static guestAllowed(state: ConfigurationStateModel) : boolean {
        return state.authentication?.guestAllowed ?? false;
    }

    @Selector()
    public static hasRegistration(state: ConfigurationStateModel) : boolean {
        return state.hasRegistration ?? false;
    }

    @Selector()
    public static phoneVerificationRequired(state: ConfigurationStateModel) : boolean {
        return state.phoneVerificationRequired ?? false;
    }

    @Selector()
    public static hasFreeTrial(state: ConfigurationStateModel) : boolean {
        return state.hasFreeTrial ?? false;
    }

    @Selector()
    public static learnMoreUrl(state: ConfigurationStateModel) : string | null | undefined {
        return state?.learnMoreUrl;
    }

    @Selector()
    public static platformName(state: ConfigurationStateModel) : string | undefined {
        return state?.name;
    }

    @Selector()
    public static privacyPolicyUrl(state: ConfigurationStateModel) : string | null | undefined {
        return state.privacyPolicyUrl;
    }

    @Selector()
    public static providerName(state: ConfigurationStateModel) : string | undefined {
        return state.authentication?.oauthConfiguration?.provider;
    }

    @Selector()
    public static reportingBackgroundUrl(state: ConfigurationStateModel) : string | null | undefined {
        return state?.theme?.reportingBackgroundUrl;
    }

    @Selector()
    public static themeColors(state: ConfigurationStateModel): ThemeModelThemeColors | undefined {
        return state.theme?.themeColors;
    }

    @Selector()
    public static themeLogos(state: ConfigurationStateModel) : LogoModel | undefined {
        return state.theme?.logo;
    }

    @Selector()
    public static trackingSentry(state: ConfigurationStateModel) {
        return state.tracking?.sentry;
    }

    @Selector()
    public static trackingMatomo(state: ConfigurationStateModel) {
        return state.tracking?.matomo;
    }

    @Selector()
    public static isMatomoTrackingEnabled(state: ConfigurationStateModel) {
        return state.tracking?.matomo?.siteId !== undefined;
    }

    @Action(UpdateApplicationConfigurationAction)
    updateApplicationConfiguration({ patchState, getState }: StateContext<ConfigurationStateModel>) {
        return this.configurationService.getConfiguration().pipe(
            map((applicationConfiguration) => patchState({
                ...getState,
                ...applicationConfiguration,
            })),
        );
    }

    @Action(UpdateApplicationConfigurationSimpleAction)
    updateApplicationConfigurationSimple({ patchState, getState }: StateContext<ConfigurationStateModel>, action: UpdateApplicationConfigurationSimpleAction) {
        return patchState({
            ...getState,
            ...action.applicationConfiguration,
        });
    }
}
