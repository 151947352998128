import {
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    ConnectedPosition,
} from '@angular/cdk/overlay';
import {
    LowerCasePipe,
    NgClass,
} from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    Optional,
    Self,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    FormsModule,
    NgControl,
    ReactiveFormsModule,
    UntypedFormControl,
} from '@angular/forms';
import {
    createMask,
    InputMaskModule,
    InputmaskOptions,
} from '@ngneat/input-mask';
import {
    UntilDestroy,
    untilDestroyed,
} from '@ngneat/until-destroy';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import {
    skip,
    Subject,
} from 'rxjs';

import {
    countries,
    Country,
} from './phone-data';
import { DropdownPanelAltComponent } from '../dropdown-panel-alt/dropdown-panel-alt.component';
import { DropdownPanelBodyComponent } from '../dropdown-panel-body/dropdown-panel-body.component';
import { IconComponent } from '../icon/icon.component';

@UntilDestroy()
@Component({
    selector: 'eb-material-input-phone-number',
    templateUrl: './material-input-phone-number.component.html',
    styleUrls: ['./material-input-phone-number.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        CdkOverlayOrigin,
        NgClass,
        IconComponent,
        FormsModule,
        ReactiveFormsModule,
        InputMaskModule,
        CdkConnectedOverlay,
        DropdownPanelAltComponent,
        DropdownPanelBodyComponent,
        LowerCasePipe,
    ],
})
export class MaterialInputPhoneNumberComponent implements AfterViewInit {
    @Input()
    public placeholder: string = '';

    @Input()
    public label: string = '';

    @Input()
    public set editOnClick(value: boolean) {
        this.editOnClickActive = value;
        this.canEdit = !value;
    }

    @Input()
    public canEdit = true;

    @Input()
    public set defaultCountry(value: CountryCode) {
        if (!this.selectedCountry) {
            this.selectedCountry = this.countries.find((item) => item.iso2 === value) ?? null;
            this.phoneMask = this.getMask();
        }
    }

    @ViewChild('input', { static: true })
    public inputField!: ElementRef<HTMLInputElement>;

    public editOnClickActive = false;

    public inputFocused = false;

    public disabled = false;

    public nextValue$ = new Subject<string>();

    public fcValue = new UntypedFormControl();

    public required: boolean = false;

    public isOpen = false;

    public positions: ConnectedPosition[] = [
        {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 0,
            offsetX: 0,
            panelClass: 'dropdown--top',
        },
        {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: 0,
            offsetX: 0,
            panelClass: 'dropdown--top',
        },
    ];

    public selectedCountry: Country | null = null;

    public countries = countries;

    public phoneMask: InputmaskOptions<unknown> | null = null;

    public constructor(
        private cd: ChangeDetectorRef,
        @Self() @Optional() private control: NgControl,
    ) {
        if (this.control) {
            this.control.valueAccessor = this;
        }
    }

    @HostListener('click')
    enableInput(): void {
        if (this.editOnClickActive && !this.canEdit) {
            this.canEdit = true;
        }
        this.cd.markForCheck();
    }

    @HostListener('focusout')
    setInputFocusOut(): void {
        if (this.editOnClickActive) {
            this.canEdit = false;
            this.nextValue$.next(this.fcValue.value);
        }
        this.cd.markForCheck();
    }

    ngAfterViewInit(): void {
        if (this.control && this.control.control) {
            this.required = this.hasRequiredField(this.control.control);
            this.cd.markForCheck();
        }
    }

    public focusInput(focused: boolean) {
        this.inputFocused = focused;
        this.cd.markForCheck();
    }

    public changeEditMode(canEdit: boolean) {
        this.canEdit = canEdit;
        if (canEdit) {
            this.inputField.nativeElement.focus();
        }
        this.cd.markForCheck();
    }

    public hasRequiredField(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }

    public writeValue(obj: string | null): void {
        if (obj) {
            const parsed = parsePhoneNumber(obj);
            this.selectedCountry = this.countries.find((item) => item.iso2 === parsed?.country) ?? null;
            this.phoneMask = this.getMask();
            if (this.selectedCountry) {
                this.fcValue.setValue(obj.replace(`+${this.selectedCountry.dialCode}`, ''), { emitEvent: false });
            }
        }
    }

    public registerOnChange(fn: (arg: unknown) => void): void {
        this.nextValue$
            .pipe(untilDestroyed(this))
            .subscribe((value) => {
                this.onTouched();
                if (value && this.selectedCountry?.iso2) {
                    const phoneNumber = `+${this.selectedCountry.dialCode}${value}`;
                    const parsed = parsePhoneNumber(phoneNumber, this.selectedCountry.iso2);
                    if (parsed?.isValid()) {
                        return fn(phoneNumber);
                    }

                    setTimeout(() => {
                        this.control.control?.setErrors({
                            ...(this.control.control?.errors ?? {}),
                            invalid_phone: true,
                        });
                        this.cd.markForCheck();
                    });

                    return fn(null);
                }
                return fn(null);
            });

        if (!this.editOnClickActive) {
            this.fcValue.valueChanges
                .pipe(
                    skip(1), // Skip the first value emiting by input mask directive
                    untilDestroyed(this),
                )
                .subscribe((value) => this.nextValue$.next(value));
        }
    }

    public setDisabledState(disabled: boolean): void {
        if (disabled) {
            this.fcValue.disable();
        } else {
            this.fcValue.enable();
        }
        this.disabled = disabled;
    }

    public selectCountry(country: Country) {
        this.selectedCountry = country;
        this.phoneMask = this.getMask();
        this.fcValue.setValue('');
        this.closeOverlay();
    }

    public openOverlay() {
        if (this.disabled) {
            return;
        }
        this.isOpen = true;
        this.cd.markForCheck();
    }

    public closeOverlay() {
        this.isOpen = false;
        this.cd.markForCheck();
    }

    public getMask() {
        if (!this.selectedCountry) {
            return null;
        }

        return createMask({
            mask: this.selectedCountry.mask,
            placeholder: 'x',
            unmaskAsNumber: true,
            autoUnmask: true,
            showMaskOnFocus: true,
            showMaskOnHover: true,
        });
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    private onTouched: () => void = () => {};
}
