// eslint-disable-next-line max-classes-per-file
import { ApplicationConfiguration } from '~/app/shared/types/configuration/application-configuration.type';

export class UpdateApplicationConfigurationAction {
    static readonly type = '[ApplicationConfiguration] Update configuration';
}

export class UpdateApplicationConfigurationSimpleAction {
    static readonly type = '[ApplicationConfiguration] Update configuration without call service';

    constructor(public applicationConfiguration?: ApplicationConfiguration) { }
}
